import {EndpointInterface} from "../EndpointInterface";
import {ApiConnector} from "../ApiConnector";
import {ModelInterface} from "../../model/ModelInterface";
import {APIRequest} from "../APIRequest";
import {NotFoundError} from "../exceptions/NotFoundError";
import {Blockplan} from "../../model/Blockplan";

export class BlockplanEndpoint implements EndpointInterface {

    connector: ApiConnector;
    endpoint: string;

    constructor(
        connector: ApiConnector
    ) {
        this.connector = connector;
        this.endpoint = "";
    }

    async getAll(): Promise<Array<Blockplan>> {

        //Frage alle Blockpläne an
        let request = new APIRequest()
            .get()
            .withUrl("blockplans");
        try {
            let response = await this.connector.request(request);
            return response as Array<Blockplan>;
        } catch (e: any) {
            if (e instanceof NotFoundError) return [];
            throw e;
        }
    }

    async getByID(id: number): Promise<Blockplan|null> {

        //Frage speziellen Blockplan über ID an
        let request = new APIRequest()
            .get()
            .withUrl("blockplans/" + id);
        try {
            let response = await this.connector.request(request);
            return response as Blockplan;
        } catch (e: any) {
            if (e instanceof NotFoundError) return null;
            throw e;
        }
    }

    async refreshAllBlockplans(): Promise<void> {
        let request = new APIRequest()
            .post()
            .withUrl("blockplans");
        await this.connector.request(request);
    }

    async update(model: ModelInterface): Promise<ModelInterface> {
        throw new Error("Not implemented");
    }

    async create(model: ModelInterface): Promise<ModelInterface> {
        throw new Error("Not implemented");
    }

    async delete(model: ModelInterface): Promise<boolean> {
        throw new Error("Not implemented");
    }


}