import React from 'react';
import {Row, Col} from 'react-bootstrap';
import '../styles/components/UserComponent.css'
import {InfoPackage} from "../model/InfoPackage";

export interface UserComponentProps {
    userdata: InfoPackage | null
}

export const UserComponent = (props: UserComponentProps) => {
    let greeting = "Hallo";
    let now = new Date();
    if(now.getHours() < 10 && now.getHours() >= 0) {
        greeting = "Guten Morgen";
    }else if(now.getHours() <= 23 && now.getHours() >= 17) {
        greeting = "Guten Abend";
    }

    const getClassLine = () => {

        // Wenn der Schüler bereits einer Klasse zugeteilt ist, wird diese angezeigt.
        // Wenn nicht, wird der Schüler gebeten in ein paar Tagen noch einmal nachzusehen.
        if(props.userdata?.clazz === null) {
            return <p className="fs-5 mt-2">Du bist noch keiner Klasse zugeteilt. Schau doch in ein paar Tagen nochmal vorbei!</p>
        }
        return <p className="fs-5 mt-2">Du bist in der Klasse <strong className="text-primary">{props.userdata?.clazz?.className??""}</strong></p>
    }

    return (

        //Zeige auf der linken Seite den Namen sowie die Klasse, und auf der rechten Seite den Blockplan des Schülers
        <Row className="mt-5 mb-4 mx-2 main-content">
            <Col md={6}>
                <span className="h1">{greeting}, {(props.userdata?.student?.first_name??"") + " " + (props.userdata?.student?.last_name??"")}</span>
                {getClassLine()}
                <p className="black-text">
                    Willkommen an der BS1 Bayreuth
                </p>
            </Col>
            <Col md={6}>
                <embed
                    src={props.userdata?.blockplan?.url}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </Col>
        </Row>

    );
}