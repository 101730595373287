import React, {useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import "../styles/components/DropzoneComponent.css"
import uploadlogo from "../ressources/upload-icon-blue.svg";
import {Button, Row} from "react-bootstrap";
import {LoadingIndicator} from "./LoadingIndicator";
import {CSVUploader} from "../api/endpoints/CSVUploader";
import {ApiConnector} from "../api/ApiConnector";

const SUCCESS_STATE_NONE = 0;
const SUCCESS_STATE_SUCCESSFUL = 1;
const SUCCESS_STATE_ERROR = 2;

export interface DropzoneComponentProps {
    connector: ApiConnector
}

export const DropzoneComponent = (props: DropzoneComponentProps) => {
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [file, setFile] = useState(null as File | null);
    const [isUploading, setUploading] = useState(false);
    const [uploadSuccessState, setUploadSuccessState] = useState(SUCCESS_STATE_NONE)
    const [requestTime, setRequestTime] = useState(0);

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        setIsFileSelected(true);
        setFile(acceptedFiles[0]);
        setUploadSuccessState(SUCCESS_STATE_NONE);
    }, []);


    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv'],
        }
    });

    let uploader = new CSVUploader(props.connector);

    const getButton = () => {

        // Wenn eine Datei hochgeladen wird, wird ein Ladesymbol angezeigt, wenn nicht, ein Button zum Beginnen des Hochladens.
        // Ist noch keine Datei ausgewählt, ist der Button deaktiviert und kann nicht geklickt werden.
        // Nach dem Hochladen, wird unter dem Button angezeigt, ob es erfolgreich war.
        if (isUploading) {
            return <LoadingIndicator title="Die Datei wird hochgeladen"/>
        } else {
            return (
                <>
                    <Button disabled={file === null} className="custom-upload-button" onClick={async () => {
                        setUploading(true);
                        if(file !== null) {
                            let start = new Date().getTime();
                            try {
                                await uploader.upload(file);
                                setUploadSuccessState(SUCCESS_STATE_SUCCESSFUL);
                            }catch (e) {
                                setUploadSuccessState(SUCCESS_STATE_ERROR);
                            }
                            let end = new Date().getTime();
                            setRequestTime(end-start);
                        }else {
                            alert("Keine Datei ausgewählt");
                        }
                        setUploading(false);
                    }}>Upload</Button>
                    <span hidden={uploadSuccessState !== SUCCESS_STATE_ERROR} className="text-danger text-center">Upload fehlgeschlagen ({requestTime}ms)<br/>Bitte prüfen Sie die Datei</span>
                    <span hidden={uploadSuccessState !== SUCCESS_STATE_SUCCESSFUL} className="text-success text-center">Upload erfolgreich ({requestTime}ms)</span>
                </>
            )
        }
    }

    return (
        <>
            <Row>
                <div {...getRootProps()} className="dropzone-div">
                    <input {...getInputProps()} />
                    {isFileSelected ? (
                        <p>{file?.name}</p>
                    ) : isDragActive ? (
                        <p>CSV-Datei hier ablegen</p>
                    ) : (
                        <>
                            <img src={uploadlogo} alt="example" className=""/>
                            <p>CSV-Datei hier ablegen oder durchsuchen</p>
                        </>
                    )}
                </div>
            </Row>
            <Row className="mt-5 justify-content-center">
                {getButton()}
            </Row>
        </>
    );
}
