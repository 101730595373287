import {APIRequest} from "./APIRequest";
import {NotFoundError} from "./exceptions/NotFoundError";

export class ApiConnector {

    protected token: string|null
    protected baseUrl: string

    /**
     * Instanziiert einen neuen ApiConnector mit angegebenen Zugangsdaten
     *
     * @param token Der zur Authentifizierung zu verwendende Token. Kann null sein, wenn noch kein Token erhalten wurde.
     * @param baseUrl Die Basis-URL des Backend-Servers
     */
    constructor(token: string|null, baseUrl: string) {
        this.token = token;
        this.baseUrl = baseUrl;
    }

    /**
     * Sendet eine Anfrage asynchron ab.
     *
     * @param _request Die vorbereitet Anfrage, die gesendet werden soll
     * @returns Ein JSON-Objekt des Antwort-Bodys, falls vorhanden. Andernfalls das Response-Objekt.
     */
    public async request(_request: APIRequest) {
        let headers = {} as HeaderType;
        if(this.token !== null) {
            headers.Authorization = "Bearer " + this.token;
        }
        let requestOptions = {} as RequestInit;
        if(_request.body() !== undefined) {
            requestOptions.body = _request.body();
            headers["Content-Type"] = "application/json";
        }

        requestOptions.method = _request.method();
        requestOptions.headers = headers as unknown as HeadersInit;

        let response = await fetch(this.baseUrl + _request.url(), requestOptions);
        if(!response.ok) {
            switch(response.status) {
                case 404:
                    throw new NotFoundError();
                default:
                    throw new Error("Request failed");
            }
        }
        try {
            return await response.json();
        }
        catch (e){
            return response;
        }

    }

    /**
     * Setzt den Token, beispielsweise nach erfolgreicher Authentifizierung
     *
     * @param token Der zu setzende Token
     */
    setToken(token: string|null) {
        this.token = token;
    }

}

interface HeaderType {
    Authorization: string|undefined
    'Content-Type': string|undefined
}
