import "../styles/User.css"
import React, {useEffect, useState} from "react";
import {HeaderComponent} from "../components/HeaderComponent";
import {FooterComponent} from "../components/FooterComponent";
import {Container} from "react-bootstrap";
import {UserComponent} from "../components/UserComponent";
import {ApiConnector} from "../api/ApiConnector";
import {useNavigate} from "react-router-dom";
import {Authenticator} from "../api/auth/Authenticator";
import {StudentInfoEndpoint} from "../api/endpoints/StudentInfoEndpoint";
import {InfoPackage} from "../model/InfoPackage";
import {BASE_PATH} from "../index";

export interface UserProps {
    connector: ApiConnector
    auth: Authenticator
}

export const User = (props: UserProps) => {
    // State für Schülerdaten zur Darstellung
    const [userdata, setUserdata] = useState(null as InfoPackage | null);

    // Navigation und Endpunkte initialisieren
    const nav = useNavigate();
    const studentInfoEndpoint = new StudentInfoEndpoint(props.connector);

    // Login prüfen und, falls ein Nutzer angemeldet ist, die Schülerdaten abfragen
    useEffect(() => {
        let func = async () => {
            if (!await props.auth.isLoggedIn()) {
                nav(BASE_PATH + "/", {replace: true});
            } else if(Authenticator.isAdmin()) {
                nav(BASE_PATH + "/upload", {replace: true});
            } else {
                // Schülerdaten abfragen und in State schreiben (UI Update)
                let userdata = await studentInfoEndpoint.getByID(parseInt(Authenticator.getUserID()));
                setUserdata(userdata);
            }
        }
        func();
    }, []);

    // Component
    return (
        <>
            <Container fluid={true} className="bgc">
                <Container>
                    <div className="user">
                        <header className="header">
                            <HeaderComponent text="Klassenübersicht" authenticator={props.auth}/>
                        </header>

                        <UserComponent userdata={userdata}/>
                        <FooterComponent/>

                    </div>
                </Container>
            </Container>
        </>
    );
};