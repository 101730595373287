/**
 * Klasse für API Anfragen
 */
export class APIRequest {

    protected _method: string;
    protected _url: string;
    protected _body: string|undefined;

    constructor() {
        this._method = "GET";
        this._url = "";
        this._body = undefined;
    }

    /**
     * GET-Methode verwenden
     */
    get(): APIRequest {
        this._method = "GET";
        return this;
    }

    /**
     * POST-Methode verwenden
     */
    post(): APIRequest {
        this._method = "POST";
        return this;
    }

    /**
     * DELETE-Methode verwenden
     */
    delete(): APIRequest {
        this._method = "DELETE";
        return this;
    }

    /**
     * Gibt aktuell verwendete HTTP-Methode zurück
     */
    method(): string {
        return this._method;
    }

    /**
     * Endpunkt setzen
     *
     * @param url Endpunkt ohne Basis-URL
     */
    withUrl(url: string): APIRequest {
        this._url = url;
        return this;
    }

    /**
     * Gibt aktuell verwendeten Endpunkt zurück
     */
    url(): string {
        return this._url;
    }

    /**
     * Anfrage-Body setzen
     *
     * @param body Body der Anfrage als Text
     */
    withBody(body: string|undefined): APIRequest {
        this._body = body;
        return this;
    }

    /**
     * Gibt aktuell verwendeten Body-Inhalt zurück
     */
    body(): string|undefined {
        return this._body;
    }

}