import {ModelInterface} from "./ModelInterface";
import {Student} from "./Student";
import {Class} from "./Class";
import {Blockplan} from "./Blockplan";

export class InfoPackage implements ModelInterface {

    public student: Student|undefined;
    public clazz: Class|undefined;
    public blockplan: Blockplan|undefined;

    constructor(
        student: Student|undefined,
        clazz: Class|undefined,
        blockplan: Blockplan|undefined
    ) {
        this.student = student;
        this.clazz = clazz;
        this.blockplan = blockplan;
    }

}