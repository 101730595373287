import '../styles/Login.css'
import "react-bootstrap";
import "../styles/Login.css"
import "../main.css"
import logo from "../ressources/Logo_bs1.png"
import {Button, Col, Container, Row} from "react-bootstrap";
import React, {KeyboardEventHandler, useEffect, useState} from "react";
import {LoadingIndicator} from "../components/LoadingIndicator";
import {useNavigate} from "react-router-dom";
import {Authenticator} from "../api/auth/Authenticator";
import {ApiConnector} from "../api/ApiConnector";
import {BASE_PATH} from "../index";

export interface LoginProps {
    connector: ApiConnector
}

export const Login = (props: LoginProps) => {

    // State für Textfelder
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginFailed, setLoginFailed] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);

    // Navigation für Weiterleitungen & Authenticator für Login
    const nav = useNavigate();
    const auth = new Authenticator(props.connector);

    // Asynchron umleiten, falls der Benutzer bereits angemeldet ist
    useEffect(() => {
        let func = async () => {
            if (await auth.isLoggedIn()) {
                nav(BASE_PATH + "/user", {replace: true});
            }
        }
        func();
    }, []);

    const handleKeypress =(ev: KeyboardEvent) => {
        //it triggers by pressing the enter key
        if (ev.keyCode === 13) {
            document.getElementById("loginSubmit")?.click();
        }
    };

    // Component für Login-Button in Funktion auslagern, um stateabhängig den Lade-Component anzuzeigen
    const getPart = () => {
        if (loggingIn) {
            return <LoadingIndicator title="Du wirst angemeldet"/>
        }
        return <ButtonPart
            username={username}
            password={password}
            setLoginFailed={setLoginFailed}
            setLoggingIn={setLoggingIn}
            authenticator={auth}
        />
    }

    // Component
    return (
        <Container fluid={true} className="login">
            <Row className="justify-content-center align-items-center custom-row">
                <Col xs={12} sm={6} md={4}>
                    <div className="custom-div-login">
                        <img src={logo} alt="example" className="logo"/>
                        <h1 className="custom-heading">Klasseninfo</h1>
                        <div className="w-100 d-flex flex-column">
                            <span hidden={!loginFailed} className="text-danger">Login fehlgeschlagen</span>
                            <input onKeyDown={handleKeypress as unknown as KeyboardEventHandler<HTMLInputElement>} onChange={(e) => setUsername(e.target.value)} type="text" placeholder="Benutzername"
                                   className="custom-input mt-2"/>
                            <input onKeyDown={handleKeypress as unknown as KeyboardEventHandler<HTMLInputElement>} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Passwort"
                                   className="custom-input mt-2"/>

                            {getPart()}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );

};

interface ButtonPartProps {
    username: string
    password: string
    setLoginFailed: any
    setLoggingIn: any
    authenticator: Authenticator
}

const ButtonPart = (props: ButtonPartProps) => {
    const nav = useNavigate();
    return (
        <Button variant="primary" className="custom-button" id="loginSubmit" onClick={async () => {
            try {
                // Anmeldeversuch
                props.setLoggingIn(true);
                await props.authenticator.login(props.username, props.password);
                props.setLoginFailed(false);
                let target = "/user";
                if(Authenticator.isAdmin()) target = "/upload"
                nav(BASE_PATH + target, {replace: true}); // Umleitung bei Erfolg
            } catch (e) {
                props.setLoginFailed(true);
            }
            props.setLoggingIn(false);
        }}>Login</Button>
    )
}