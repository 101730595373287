import {ApiConnector} from "../ApiConnector";
import {APIRequest} from "../APIRequest";
import * as jose from 'jose';
import {StudentEndpoint} from "../endpoints/StudentEndpoint";
import {NotFoundError} from "../exceptions/NotFoundError";

export class Authenticator {

    protected connector: ApiConnector;

    constructor(connector: ApiConnector) {
        this.connector = connector;
    }

    /**
     * Führt einen Anmeldeversuch mit gegebenen Zugangsdaten aus und setzt bei Erfolg
     * die sessionStorage-Werte
     *
     * @param username
     * @param password
     */
    async login(username: string, password: string): Promise<void> {

        // Token anfragen
        let request = new APIRequest()
            .post()
            .withUrl("authenticate")
            .withBody(JSON.stringify({
                username: username,
                password: password
            }));
        let response = await this.connector.request(request);
        let token = response.token;

        // Token auslesen und User-ID aus Claims bestimmen
        this.applyTokenClaims(token);
        if(!await this.isLoggedIn()) {
            throw new NotFoundError();
        }
    }

    /**
     * Setzt die sessionStorage-Werte anhand von einem gegebenen Token
     *
     * @param token
     */
    applyTokenClaims(token: string) {
        let claims = jose.decodeJwt(token) as unknown as AuthenticatorClaims;
        let authority = claims.authorities[0].authority;
        let userId = authority.split("_")[1] ?? 0;

        // Nutzerdaten in der Session Storage speichern
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("authority", authority);
        sessionStorage.setItem("userId", userId.toString());
    }

    /**
     * Aktualisiert zunächst die sessionStorage-Werte anhand des gespeicherten Tokens und prüft anschließend,
     * ob diese gültig sind. Ist ein Schüler angemeldet wird zusätzlich geprüft, ob der Datensatz existiert und
     * abgefragt werden kann.
     */
    async isLoggedIn(): Promise<boolean> {
        let token = Authenticator.getToken();
        if(token == null) return false;
        // Manipulation verhindern
        this.applyTokenClaims(token);
        // Benutzer abfragen, um Zugriff zu validieren
        this.connector.setToken(token);
        let studentEndpoint = new StudentEndpoint(this.connector);
        try {
            if(!Authenticator.isAdmin()) {
                let student = await studentEndpoint.getByID(parseInt(Authenticator.getUserID()));
                return student !== null;
            }
            return Authenticator.getUserID() == "ADMIN";
        }catch (e) {
            return false;
        }
    }

    /**
     * Zerstört die Sitzungsdaten
     */
    logout() {
        // Nutzerdaten aus der Session Storage löschen
        sessionStorage.setItem("token", "");
        sessionStorage.setItem("authority", "");
        sessionStorage.setItem("userId", "");
        sessionStorage.clear();
    }

    static getToken(): string|null {

        //Token aus der Session Storage holen
        return sessionStorage.getItem("token");
    }

    static getUserID(): string {

        //UserID aus der Session Storage holen
        return sessionStorage.getItem("userId") ?? "0";
    }

    static isAdmin(): boolean {

        //UserID aus der Session Storage holen
        return Authenticator.getUserID() === "ADMIN";
    }

}

interface AuthenticatorClaims {
    sub: string,
    exp: number,
    iat: number,
    authorities: Array<Authority>
}

interface Authority {
    authority: string
}