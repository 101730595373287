import {EndpointInterface} from "../EndpointInterface";
import {ApiConnector} from "../ApiConnector";
import {ModelInterface} from "../../model/ModelInterface";
import {Student} from "../../model/Student";
import {APIRequest} from "../APIRequest";
import {NotFoundError} from "../exceptions/NotFoundError";
import {InfoPackage} from "../../model/InfoPackage";
import {Class} from "../../model/Class";
import {Blockplan} from "../../model/Blockplan";

export class StudentInfoEndpoint implements EndpointInterface {

    connector: ApiConnector;
    endpoint: string;

    constructor(
        connector: ApiConnector
    ) {
        this.connector = connector;
        this.endpoint = "";
    }

    async getAll(): Promise<Array<Student>> {
        throw new Error("Not implemented");
    }

    async getByID(id: number): Promise<InfoPackage|null> {

        //Frage spezielle Schülerinfo über ID an
        let request = new APIRequest()
            .get()
            .withUrl("student-info/" + id);
        try {
            let response = await this.connector.request(request);
            return new InfoPackage(
                response.studentsDto as Student,
                response.classesDto as Class,
                response.blockplanDto as Blockplan
            );
        } catch (e: any) {
            if (e instanceof NotFoundError) return null;
            throw e;
        }
    }

    async update(model: ModelInterface): Promise<ModelInterface> {
        throw new Error("Not implemented");
    }

    async create(model: ModelInterface): Promise<ModelInterface> {
        throw new Error("Not implemented");
    }

    async delete(model: ModelInterface): Promise<boolean> {
        throw new Error("Not implemented");
    }


}