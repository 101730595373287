import '../styles/components/LoadingIndicator.css'

export interface LoadingIndicatorProps {
    title: string|null
}

export const LoadingIndicator = (props: LoadingIndicatorProps) => {

    const getTitle = () => {
        if(props.title !== null) return <small>{props.title}</small>;
        return <></>;
    }

    let className = "loadingSpinner";
    if(props.title === null) className += " loadingSpinnerSmall";

    return (
        <>
            <div className="loadingPart">
                <div className={className}></div>
                {getTitle()}
            </div>
        </>
    )
}