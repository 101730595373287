import {EndpointInterface} from "../EndpointInterface";
import {ApiConnector} from "../ApiConnector";
import {ModelInterface} from "../../model/ModelInterface";
import {Student} from "../../model/Student";
import {APIRequest} from "../APIRequest";
import {NotFoundError} from "../exceptions/NotFoundError";

export class StudentEndpoint implements EndpointInterface {

    connector: ApiConnector;
    endpoint: string;

    constructor(
        connector: ApiConnector
    ) {
        this.connector = connector;
        this.endpoint = "";
    }

    async getAll(): Promise<Array<Student>> {

        //Frage alle Schüler an
        let request = new APIRequest()
            .get()
            .withUrl("students");
        try {
            let response = await this.connector.request(request);
            return response as Array<Student>;
        } catch (e: any) {
            if (e instanceof NotFoundError) return [];
            throw e;
        }
    }

    async getByID(id: number): Promise<Student|null> {

        //Frage speziellen Schüler über ID an
        let request = new APIRequest()
            .get()
            .withUrl("students/" + id);
        try {
            let response = await this.connector.request(request);
            return response as Student;
        } catch (e: any) {
            if (e instanceof NotFoundError) return null;
            throw e;
        }
    }

    async update(model: ModelInterface): Promise<ModelInterface> {
        throw new Error("Not implemented");
    }

    async create(model: ModelInterface): Promise<ModelInterface> {
        throw new Error("Not implemented");
    }

    async delete(model: ModelInterface): Promise<boolean> {
        throw new Error("Not implemented");
    }


}