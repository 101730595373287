import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import logo from "../ressources/Logo_bs1.png"
import {Authenticator} from "../api/auth/Authenticator";
import {useNavigate} from "react-router-dom";
import {BASE_PATH} from "../index";

export interface HeaderComponentProps {
    text: string
    authenticator : Authenticator
}

export const HeaderComponent = (props: HeaderComponentProps) => {

    const nav = useNavigate();

    return (
        <div className="py-3">
            <Container style={{borderBottom: "#3333 1px solid"}}>
                <Row className="align-items-center">
                    <Col xs={2}>
                        <a href="https://bs1-bt.de/" target="_blank">
                            <img src={logo} alt="Image" className="img-fluid"/>
                        </a>
                    </Col>
                    <Col xs={8}>
                        <h1 className="text-black text-center">{props.text}</h1>
                    </Col>
                    <Col xs={2}>
                        <a href="#" className="black-text" onClick={async () => {
                            await props.authenticator.logout();
                            nav(BASE_PATH + "/", {replace: true});
                        }}>Abmelden</a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}