import {ApiConnector} from "../ApiConnector";
import {APIRequest} from "../APIRequest";

export class CSVUploader {

    connector: ApiConnector;
    endpoint: string;

    constructor(
        connector: ApiConnector
    ) {
        this.connector = connector;
        this.endpoint = "csv";
    }

    public async upload(file: File) {

        //Lade ausgewählte CSV hoch
        let request = new APIRequest()
            .post()
            .withBody(await file.text())
            .withUrl(this.endpoint);
        await this.connector.request(request);
    }

}