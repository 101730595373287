import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Login} from "./pages/Login";
import {PageNotFound} from "./pages/PageNotFound";
import "./index.css"
import {User} from "./pages/User";
import {Settings} from "./pages/Settings";
import {ApiConnector} from "./api/ApiConnector";
import {Authenticator} from "./api/auth/Authenticator";

export const BASE_PATH = ""; // Hier kann ein URL-Pfad vorangestellt werden, falls diese App nicht der Root des Webservers ist
export const API_ENDPOINT = "https://the-klasseninfo.lukas-10100.de/api/"; // Hier muss die Basis-URL der Backend-API angegeben werden

// Definieren der wichtigen API Klassen
let api = new ApiConnector(null, API_ENDPOINT);
const auth = new Authenticator(api);

// Hauptkomponente
export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={BASE_PATH + "/"}>
                    {/* Hier werden Komponenten der Unterseiten registriert */}
                    <Route index element={<Login connector={api} />}/>
                    <Route path={BASE_PATH + "/user"} element={<User connector={api} auth={auth}/>}/>
                    <Route path={BASE_PATH + "/*"} element={<PageNotFound/>}/>
                    <Route path={BASE_PATH + "/upload"} element={<Settings connector={api} auth={auth} />}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

// Output
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);