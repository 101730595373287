import React, {useEffect, useState} from "react";
import "react-bootstrap";
import "../styles/Settings.css";
import {Button, Col, Container, Row} from "react-bootstrap";
import {DropzoneComponent} from "../components/DropzoneComponent";
import {HeaderComponent} from "../components/HeaderComponent";
import {ApiConnector} from "../api/ApiConnector";
import {Authenticator} from "../api/auth/Authenticator";
import {useNavigate} from "react-router-dom";
import {BlockplanEndpoint} from "../api/endpoints/BlockplanEndpoint";
import {LoadingIndicator} from "../components/LoadingIndicator";
import {BASE_PATH} from "../index";

const SUCCESS_STATE_NONE = 0;
const SUCCESS_STATE_SUCCESSFUL = 1;
const SUCCESS_STATE_ERROR = 2;

export interface SettingsProps {
    connector: ApiConnector
    auth: Authenticator
}

export const Settings = (props: SettingsProps) => {

    const [loadingBlockplans, setLoadingBlockplans] = useState(false);
    const [refreshSuccessState, setRefreshSuccessState] = useState(SUCCESS_STATE_NONE)

    const blockplanEndpoint = new BlockplanEndpoint(props.connector);

    // Navigation und Endpunkte initialisieren
    const nav = useNavigate();

    // Login prüfen und, falls ein Nutzer angemeldet ist, die Schülerdaten abfragen
    useEffect(() => {
        let func = async () => {
            if (!await props.auth.isLoggedIn()) {
                nav(BASE_PATH + "/", {replace: true});
            }else if(!Authenticator.isAdmin()) {
                nav(BASE_PATH + "/user", {replace: true});
            }
        }
        func();
    }, []);

    const getButtonContent_UpdateBlockplans = () => {
        if(loadingBlockplans) {
            return <LoadingIndicator title={null} />
        }else {
            return <>Blockpläne neu einlesen</>
        }
    }

    return (
        <>
            <Container fluid={true}>
                <div className="Upload ">
                    <Row>
                        <header className="header">
                            <HeaderComponent text="Einstellungen" authenticator={props.auth}/>
                        </header>
                    </Row>
                    <Row className="justify-content-center align-items-center mt-5">
                        <Col xs={4}>
                            <Row>
                                <DropzoneComponent connector={props.connector}/>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="justify-content-center align-items-start text-center mt-5">
                        <Col xs={6}>
                            <Button disabled={true} variant="primary" className="custom-settings-button">Datenbank löschen</Button>
                        </Col>
                        <Col xs={6}>
                            <Button variant="primary" className="custom-settings-button" onClick={async () => {
                                setLoadingBlockplans(true);
                                try {
                                    await blockplanEndpoint.refreshAllBlockplans();
                                    setRefreshSuccessState(SUCCESS_STATE_SUCCESSFUL);
                                }catch (e) {
                                    setRefreshSuccessState(SUCCESS_STATE_ERROR);
                                }
                                setTimeout(() => setRefreshSuccessState(SUCCESS_STATE_NONE), 2000);
                                setLoadingBlockplans(false);
                            }}>{getButtonContent_UpdateBlockplans()}</Button>
                            <br/>
                            <span hidden={refreshSuccessState !== SUCCESS_STATE_ERROR} className="text-danger text-center">Fehler beim Eingelesen</span>
                            <span hidden={refreshSuccessState !== SUCCESS_STATE_SUCCESSFUL} className="text-success text-center">Erfolgreich eingelesen</span>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );

};