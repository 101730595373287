import React from 'react';
import '../styles/components/FooterComponent.css'
import {Row, Col} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";


export function FooterComponent() {

    const nav = useNavigate();

    return (
        <div className="footer-container">
            <div className="footer-content">
                <Row className="footer-up">
                    <Col md={6}>
                        <div className="mb-1">
                            <span className="white-text">Staatliche Berufsschule I </span>
                        </div>
                        <div className="mb-1">
                            <span className="white-text">Kerschensteinerstraße 6 </span>
                        </div>
                        <div>
                            <span className="white-text">95448 Bayreuth</span>
                        </div>
                    </Col>
                    <Col md={6} className="d-flex justify-content-md-end">
                        <div className="text-end-md">
                            <div className="mb-1">
                                <a className="white-text" href="tel:0921/5073936-0">Tel: 09 21 / 50 73 93 6 - 0</a>
                            </div>
                            <div className="mb-1">
                                <span className="white-text">Fax: 09 21 / 50 73 93 6 - 99</span>
                            </div>
                            <a className="white-text ml-2" href="https://bs1-bt.de/allgemeines/kontakt">Kontaktformular</a>
                        </div>
                    </Col>
                </Row>
                <Row className="footer-down">
                    <Col md={6}>
                        <div className="text-center-md">
                            <a href="https://bs1-bt.de/impressum" className="white-text">Impressum</a>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="text-center-md">
                            <a href="https://bs1-bt.de/datenschutz" className="white-text">Datenschutz</a>
                        </div>
                    </Col>
                </Row>
                <Row className="footer-copyr">
                    <Col>
                        <div className="text-center-md white-text fs-6">Schüler Johannes Fränkel, Lukas Löffler, Samuel
                            Spörl, Lukas Wolfrum
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}